import styled from "styled-components";
import { Link } from "react-scroll";


export const Container= styled.div`
    max-height: 740px;
    position: relative;
    z-index: 2;
    background-color: #42ABAE;
` 
export const FormWrap=styled.div`;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 400px){
        height: 80%;
    }
`

export const FormH1=styled.h1`
    margin-bottom: 40px;
    color: #fbbc14;
    font-family: 'Love Ya Like A Sister', cursive;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
`

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top 8px;
    }
`
export const FormContent = styled.div`
    position: center;
    @media screen and (max-width: 480px){
        padding: 10px;
    }
`

export const Form = styled.form`
    background: #0d9ba3;
    max-width: 400px;
    position: relative;
    height: auto;
    margin: 20px;
    position: center;
    width: 100%;
    z-index: 1;
    display: grid;

    padding: 80px 32px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    @media screen and (max-width: 480px){
        padding: 10px;
        right: 6%;
    }
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 18px;
    color: #fff;
    font-family: 'Love Ya Like A Sister', cursive;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`
export const FormInputMSG = styled.textarea`
    padding: 36px 36px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`

export const FormButton = styled.button`
    background: #fbbc14;
    padding: 16px 0;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Love Ya Like A Sister', cursive;
`

export const Text = styled.span`
   text-align: center;
   margin-top: 24px;
   color: #fff;
   font-size: 14px;
`
export const BirdIcon=styled.img`
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;
    height: 260px;
    width: 260px;
    // left: 45%;
    top: -20%;
`